import {
  inject,
  Injectable,
  Injector,
  NgZone,
  PLATFORM_ID,
} from "@angular/core";
import { forkJoin, of } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { TranslateHelperService } from "@app/helpers/translate-helper";

import { AuthCoreService } from "../auth";
import { CartApiService } from "../cart";

import { ShopApiService } from "../shop";
import { ApiStatusService } from "../status";
import { isPlatformBrowser } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class InitializerService {
  private platformId = inject(PLATFORM_ID);
  private ngZone = inject(NgZone);
  constructor(private injector: Injector) {}

  load() {
    return new Promise((resolve, reject) => {
      console.log(this.platformId);

      if (!isPlatformBrowser(this.platformId)) {
        resolve(true);
      }

      if (isPlatformBrowser(this.platformId)) {
        const authService = this.injector.get(AuthCoreService);
        const cartService = this.injector.get(CartApiService);
        const translateService = this.injector.get(TranslateHelperService);
        const apiStatusService = this.injector.get(ApiStatusService);
        const shopService = this.injector.get(ShopApiService);
        this.ngZone.runOutsideAngular(() => {
          const initLanguage = translateService.init();

          forkJoin({
            initLanguage,
            authService: authService.accessTokenInit(),
          })
            .pipe(
              switchMap(() => apiStatusService.core_api_status_get()),

              switchMap((state) => {
                if (state) {
                  return forkJoin({
                    shop: shopService.init(),

                    cartInit: cartService.init(),
                  });
                } else {
                  return of(state);
                }
              }),
              switchMap((state) => {
                if (state) {
                  return cartService.retrieveCartFromServer();
                } else {
                  return of(state);
                }
              }),

              catchError((e) => {
                return of(false);
              })
            )
            .subscribe({
              next: (res) => {
                resolve(true);
              },
            });
        });
      }
    });
  }
}

export function initProviderFactory(provider: InitializerService) {
  return () => provider.load();
}
